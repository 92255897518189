import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Chatbot Developers | Offshore Chatbot Development Company in India',
    metaDescription: 'Our Chatbot developer builds interactive and customized secure Chatbot applications for your business at the lowest cost.',
    h1: 'Hire Dedicated Chatbot Developers',
    h1Subtext1: 'Build custom chatbot to increase lead generation and sales for your business. ',
    h1Subtext2: 'MatteCurve is the best chatbot development company in India. We are a JavaScript company and have dedicated chatbot developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire chatbot developers within 7 days.',
    aboutSection: {
        title: 'About Chatbot Development',
        subTitle: 'Build robust bots, interactive & Automate your customer service to improve business efficiency.',
        paragraph1: "A chatbot is a software application for intelligent interactions with human messaging applications, on a website, a messaging app or a virtual assistant. ChatBot is a way to communicate and can engage customers and drive your business forward.",
        paragraph2: "It is a form of artificial intelligence (AI) which is used in on-line chat conversation via text or text-to-speech. It is easier for people to simulate a conversation (or a chat) between humans and machines through messaging applications, websites, mobile apps or through the telephone.",
        paragraph3: `
        Building a chatbot requires front-end and back-end skills. 
        On the back-end site we build a chatbot server which is responsible for receiving and sending messages.
        Front-end chatbot interface can be build using HTML, CSS and Javascript. It might require some libraries which are 
        required to connect to a chatbot server. There are many use cases for chatbot. Some of them are:
        online shopping, healthcare support, crm, providing details of a product etc. 
        Hire Chatbot developers from MatteCurve who are experienced and skilled in developing large scale chatbot applications.
        `,
        keyPoints: [
            "A Chatbot is a software that can interact via a chat interface with a user in Natural Language through on-line chat conversation.",
            "Chatbot helps to increase their website conversion rate and generate more qualified leads.",
            "Chatbot should be able to provide customer service, actionable records of your customers, useful information to customers and improve its products and services.",
            "Chatbot automates conversational elements flow and provides customer engagement with an interesting experience to your customers.",
            "Build customized, interactive and  specialized tailor-made chatbot applications and offer their clients an improved experience.",
            "Chatbot can help users by providing answers to their relevant questions, retail businesses, like online shopping, tickets etc. and qualified leads at scale.",
            "MatteCurve uses cutting edge technology in delivering quality and tailor-made rich chatbot applications which are AI-powered & Machine Learning coded."
        ],
    },
    statsSection: {
        paragraph: "According to an estimate, approximately 85% of their customer interaction uses chatbots which has been influenced by AI. Most of the big companies like Amazon, Google have their own chatbots. We could notice a number of amazing and popular apps in the app stores built for these bots are Alexa, Siri, Google Assistant, Whatsapp, Facebook Messenger and many more. Hire Dedicated Chatbot Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated Chatbot Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated Chatbot Developers?",
        "Hire dedicated Chatbot Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for Chatbot Development?",
        paragraph: "MatteCurve is one of the best-in-class chatbot development companies in India that involves developing interactive multi-channel chatbot solutions for our clients. We endeavor to build you efficient bots that are knowledge of presentation, conversational interfaces ,high-performing, scalable and secured Chatbots.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
